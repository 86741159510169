@font-face {
    font-family: 'poppinslight';
    src: url('../../../assets/fonts/poppins-light-webfont.woff2') format('woff2'),
         url('../../../assets/fonts/poppins-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  
  }
  
  @font-face {
    font-family: 'poppinsmedium';
    src: url('../../../assets/fonts/poppins-medium-webfont.woff2') format('woff2'),
         url('../../../assets/fonts/poppins-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  
  }
  
  @font-face {
    font-family: 'poppinsregular';
    src: url('../../../assets/fonts/poppins-regular-webfont.woff2') format('woff2'),
         url('../../../assets/fonts/poppins-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  
  } 
  
  body{margin: 0;padding: 0;font-family: 'poppinsregular';}
  .top_bar_section{
    width: 100%;
    height: auto;
    float: left;
    background: linear-gradient(135deg, #46346a 0%, #602aa5 99%);
    padding:10px 0;
  }
  .scrollbtn{
    background-color: #21194e!important;
  }
  .mailboxMainContent{
    background-color: #00a4ff !important;
  }
  .main_header_sec{
    width: 100%;
    //height: auto;
    float: left;
    box-shadow: 0px 1px 18px rgb(0, 0, 0 / 10%);
    position: fixed;
    left: 0;
    top: 0;
    background-color: #fff;
    z-index: 1000;
  }
  .main_logo{
    width: 100%;
    height: auto;
    float: left;
  }
  .main_logo img{max-width: 300px;}
  .login_btn{
    padding: 10px 30px !important;
    background-color: #00a4ff;
    color: #fff !important;
    font-size: 16px;
    border-radius: 40px;
    transition: 0.2s ease;
  }

  .avatarImage{
    overflow: visible;
    border-radius: 50%;
    width: 120px;
    height: 120px !important;
    margin: 0 auto;
    position: relative;
    max-width: 205px;
    margin: 0 auto;
    border: none;
    .avatarEdit{
        position: absolute;
        right: 12px;
        z-index: 1;
        bottom: 0;
        input{
            display: none;
        }
        label {
            color: #fff;
            display: inline-block;
            width: 34px;
            height: 34px;
            margin-bottom: 0;
            border-radius: 100%;
            background: #201e1ed4;
            // box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 12%);
            cursor: pointer;
            font-weight: normal;
            transition: all 0.2s ease-in-out;
            &::after{
                content: "\f040";
                font-family: 'FontAwesome';
                color: #fff;
                position: absolute;
                top: 10px;
                left: 0;
                right: 0;
                text-align: center;
                margin: auto;
                cursor: pointer;
                top: 5px;
            }
        }
    }
    .avatarPreview{
        width: 125px;
        height: 125px;
        position: relative;
        border-radius: 100%;
        // box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 10%)
        img{
            width: 100%;
            height: 100%;
            border-radius: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
}
  .login_btn:hover{background-color: #242424;}
  .banner_section{
    width: 100%;
    float: left;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100vh;
    text-align: center;
  
  }
  .top_bar_section{
    width: 100%;
    height: auto;
    float: left;
  }
  // .rightContent{
  //   img{
  //     border-right: 30px solid #3f2b7a;
  //   }
  // }

  .textfieldss{
    // border-bottom: 30px solid #3f2b7a;
    // border-bottom-right-radius: 5em;
    p{
      margin-bottom: 1em;
    }
  }
  .top_cnt_sec{
    width: 100%;
    height: auto;
    float: left;
    display: flex;
    align-items: center;
    justify-content: end;
  }
  .top_cnt_sec_box{
    color: #fff;
    font-size: 14px;
    margin-left: 16px;
  }
  .navbar_expand_lg .navbar_nav .nav_link{
    padding-right: 20px;
    padding-left: 20px;
    text-transform: uppercase;
    color: #242424;
  }
  .menubar_sec{margin-top: 10px;}
  .banner_contant_sec{
    width: 100%;
    height: auto;
    float: left;
    margin-top: 60px;
  }
  .banner_contant_sec h3{color: #666;margin-bottom: 20px;display: inline-block;}
  .banner_button{
    width: 150px;
    height: 50px;
    background-color: #00a4ff;
    text-align: center;
    line-height: 50px;
    color: #fff;
    margin-top: 10px;
    border-radius: 30px;
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
  }
  .banner_button:hover{background-color: #242424;color: #fff;cursor: pointer;}
  .plan_cnt_sec{
    width: 100%;
    height: auto;
    float: left;
    padding:90px 0;
    background-image: url(../../../assets/images/dot-big-square.svg);
    background-repeat: no-repeat;
    background-position: 270px 112px;
    min-height: 400px;
  }
  .plan_cnt_sec_head{
    width: 100%;
    height: auto;
    float: left;
    text-align: center;
    color: #242424;
    margin-bottom: 30px;
    font-family: 'poppinsmedium';
  }
  .plan_cnt_sec_head h2{
    font-size: 40px;
  }
  
  .plan_box_sec{
    width: 100%;
    height: auto;
    float: left;
    padding:40px 20px;
    border-radius: 10px;
    box-shadow: 10px 3px 20px 0 rgb(210 ,210, 245 / 50%);
    background-color: #fff;
    margin-top: 10px;
  }
  .plan_box_head{
    width: 100%;
    height: auto;
    float: left;
    text-align: center;
    color: #242424;
    font-size: 25px;
  }
  .plan_box_head h3{margin: auto; color: #242424;  font-size: 25px;}
  .plan_box_cnt{
    width: 100%;
    height: auto;
    float: left;
    text-align: center;
    font-size: 16px;
    color: #666;
    line-height: 28px;
    margin-top: 20px;
  }
  .plan_box_cnt p{
    font-size: 16px;
    color: #666;
  }
  .about_section{
    width: 100%;
    height: auto;
    float: left;
    padding: 80px 0;
    background-color: #f3f3f3;
  }
  .about_section_contant{
    width: 100%;
    height: auto;
    float: left;
  }
  .about_sectioncnt_sec_head{
    width: 100%;
    height: auto;
    float: left;
    text-align: center;
    color: #242424;
    font-size: 18px;
  }
  .about_sectioncnt_sec_head h2{
    width: 100%;
    height: auto;
    float: left;
    text-align: center;
    color: #242424;
    font-family: 'poppinsmedium';
    font-size: 40px;
  }
  .about_section_contant_head{
    width: 100%;
    height: auto;
    float: left;
    color: #242424;
    font-size: 18px;
    margin-top: 50px;
    font-family: 'poppinsmedium';
  }
  
  .about_section_contant_paragp{
    width: 100%;
    height: auto;
    float: left;
    text-align: left;
    font-size: 16px;
    color: #666;
    line-height: 28px;
    margin-top: 20px;
  }
  .about_section_contant_paragp p{
    text-align: left;
    font-size: 16px;
    color: #666;
  }
  
  .about_section_img{
    width: 100%;
    height: auto;
    float: left;
    text-align: center;
  }
  .about_section_img img{width: 80%;}
  
  .contact_section{
    width: 100%;
    height: auto;
    float: left;
    padding: 80px 0;
  }
  .contact_section_head h2{
    width: 100%;
    height: auto;
    float: left;
    color: #242424;
    font-family: 'poppinsmedium';
    font-size: 40px;
  }
  .contact_section_head{
    width: 100%;
    height: auto;
    float: left;
    color: #242424;
    font-size: 18px;
    margin-top: 50px;
    
  }
  .contact_bx_phone{
    width: 100%;
    height: auto;
    float: left; 
    margin-top: 30px;
  }
  .contact_bx_phone_ico{
    width: 40px;
    height: 40px;
    float: left;
    background-color: #d0eeff;
    border-radius: 50px;
    text-align: center;
    line-height: 40px;
    color: #242424;
  }
  .contact_bx_phone span{
    width: 100%;
    height: auto;
    float: left; 
    text-align: left;
    font-size: 18px;
    color: #242424;
    margin-top: 10px;
  }
  .contact_bx_phone strong{
    width: 100%;
    height: auto;
    float: left; 
  
  }
  .form_sec_box{
    width: 100%;
    height: auto;
    float: left; 
    padding: 20px;
    box-shadow: 5px 0.8rem 3rem rgb(0 ,0 ,0 / 15%) !important;
    background-color: #fff;
    margin-top: 30px;
  }
  .form_sec_box h4{
    width: 100%;
    height: auto;
    float: left; 
    text-align: left;
    font-size: 24px;
    color: #242424;
    margin-top: 10px;
    font-family: 'poppinsmedium';
  }
  .form_sec_box_group{
    width: 100%;
    height: auto;
    float: left; 
    margin-top: 30px;
  }
  .form_sec_box_group .group{
    width: 100%;
    height: auto;
    float: left; 
    margin-bottom: 15px;
  }
  .fooer_link{
    text-align: right;
  }
  .footer_lft{
    font-size: 14px;
  }
  .fooer_link a{
    color: #666;
    text-decoration: none;
    font-size: 14px;
    margin-left: 10px;
  }
  .footer_sec{
    width: 100%;
    height: auto;
    float: left; 
    padding: 20px 0;
    background-color: #f3f3f3;
  }
  .row{clear: both;}
  .scrolltop{
    position: fixed;
    bottom: 23px;
    right: 25px;
    color: #fff;
    width: 54px;
    height: 38px;
    line-height: 30px;
    padding: 2px;
    text-align: center;
    z-index: 1000;
    background-color: #6833ad !important;
    border-color: #6833ad !important;
  
  }
  .scrollbtn:hover{
    background-color: #33c18c !important;
  }
  



@media (max-width:991px){
    .mob_full_sec{position: absolute;right: 0;width: 100%;}
    .navbar_toggler{position: absolute; top: 14px; right: 11px;}
    .navbar-collapse{align-items: center; background-color: #fff; margin-top: 67px; box-shadow: 0px 0px 10px #00000029; padding: 20px;}
    .fooer_link{text-align: center;margin-top: 10px;}
    .footer_lft{text-align: center;}
}
@media (max-width:768px){
    .main_logo{max-width: 200px;padding: 10px 0;}
    .main_logo img{width: 100%;}
}
@media (max-width:500px){
    .top_cnt_sec{display: inline-block;  text-align: center;}
}

.langContainer {
  min-height: auto;
  min-width: 70px;
  margin-left: -30px;
  .langButton{
      position: relative;
      display: block;
      cursor: pointer;
      height: auto;
      &.mainlayout{
          padding-top:10px ;
      }
      &:hover{
          background: transparent;
      }
  }
  @media (max-width: 991px) {
      min-width: 60px;
    }
  .langFlag{
      width: 21px;
      
  }
  .selectedLang{
      position: absolute;
      width: 100%;
      min-height: -webkit-fill-available;
      padding: 0px;
      vertical-align: middle;
      left: 0;
      color: #fff;
  }
  .caretIcon{
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: 5px;
      vertical-align: middle;
      border-top: 4px dashed;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
  }
  .languageDropDown{
      top:50px !important;
      position: absolute;
  }


}
.navitem:hover{
  color:#020405
}
